// Configuration used to describe dynamic data in that is displayed in the graph
// Not all the options is always present in the data, so we need to check if the data is present
// Key is the name of the data in the API response
const sourcesConfig = {
  AdX: {
    logo: 'https://www.gstatic.com/admanager/ad_manager.ico',
    name: 'AdX',
    colorKey: 'adx',
  },
  AdSense: {
    logo: 'https://storage.googleapis.com/support-kms-prod/bJppRS0Z8FmVujFmM7KFHADzbq3L0PPb1Wpj',
    name: 'Adsense',
    colorKey: 'adsense',
  },
  Prebid: {
    logo: 'https://prebid.org/wp-content/uploads/2023/06/cropped-prebid-logo-180x180.png',
    name: 'Prebid',
    colorKey: 'prebid',
  },

  Amazon: {
    logo: 'https://advertising.amazon.com/favicon.ico',
    name: 'Amazon',
    colorKey: 'amazon',
  },
  'Open Bidding': {
    logo: 'https://www.google.com/favicon.ico',
    colorKey: 'eCPM',
  },
};

// Metrics that are used in the graph
const createMetricsConfig = (theme, hideSeparateGraphs) => ({
  fixed: {
    requests: {
      dataField: 'All Imp',
    },
    date: {
      dataField: 'date',
    },
  },
  perSource: {
    impressions: {
      dataField: 'Paid Imp',
      displayName: 'Impressions',
      comparisonWithTotalEnabled: true,
      cumulative: true,
      hideIndicator: false,
      graphValueAxisConfig: {
        stackType: 'regular',
        id: 'impressions',
        title: 'Impressions',
      },
      indicatorType: 'column',
      indicatorStyle: {
        height: 42,
        width: 15,
        display: 'inline-block',
        border: `1px solid ${theme.palette.graphColors.indicatorBorder}`,
        marginLeft: 10,
        borderRadius: 2,
        top: 33,
        position: 'absolute',
      },
    },
    revenue: {
      dataField: 'Rev',
      displayName: 'Revenue',
      symbol: '€',
      comparisonWithTotalEnabled: true,
      cumulative: true,
      hideIndicator: false,
      graphValueAxisConfig: {
        stackType: 'regular',
        position: 'right',
        id: 'revenue',
        title: 'Revenue',
      },
      indicatorType: 'line',
      indicatorStyle: {
        height: 4,
        width: 40,
        display: 'inline-block',
        position: 'absolute',
        top: 32,
      },
    },
    eCPM: {
      dataField: 'eCPM',
      displayName: 'eCPM',
      symbol: '€',
      comparisonWithTotalEnabled: false,
      cumulative: false,
      hideIndicator: hideSeparateGraphs,
      graphValueAxisConfig: {
        id: 'eCPM',
        title: 'eCPM',
        position: 'right',
      },
      indicatorType: 'dashed-line',
      indicatorStyle: {
        height: 1,
        width: 40,
        display: 'inline-block',
        position: 'absolute',
        top: 32,
      },
    },
  },
});

const balloonConfig = {
  adjustBorderColor: false,
  borderColor: '#e75d3c000',
  fillColor: '#e75d3c000',
  fillAlpha: 0,
  shadowAlpha: 0,
  color: '#fff',
  cornerRadius: 4,
  horizontalPadding: 10,
  verticalPadding: 10,
};

module.exports = {
  sourcesConfig,
  createMetricsConfig,
  balloonConfig,
};
