import React, {useState, useEffect, useRef} from 'react';
import AmCharts from '@amcharts/amcharts3-react/index';
import {useTheme} from '@material-ui/core/styles';
import {
  getStructuredFixedGraphDataForTheDay,
  getAvailableSources,
  getStructuredDynamicGraphDataForTheDay,
  getTotals,
  getPercents,
  getMaxValueForGraph,
} from './helpers/dataHelpers';
import {
  Grid,
  Tooltip,
  Typography,
  makeStyles,
  Button,
  FormControlLabel,
  Switch,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Table,
  Paper,
  withStyles,
  Checkbox,
} from '@material-ui/core';
import millify from 'millify';
import moment from 'moment';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import VisibilityIcon from '@material-ui/icons/Visibility';
import {sourcesConfig, createMetricsConfig, balloonConfig} from './helpers/configs';

const useStyles = makeStyles(theme => ({
  domainImage: {
    height: 24,
    width: 24,
  },
  sourceRow: {borderBottom: `1px solid #7979793b`, paddingBottom: 4, marginTop: 4, marginBottom: 4},
}));

const StyledTableCell = withStyles(theme => ({
  head: {
    background: theme.palette.graphColors.themeDarkGray,
    borderBottom: `1px solid ${theme.palette.graphColors.themeDarkGray}`,
    fontWeight: 'bold',
    position: 'relative',
    padding: 8,
    cursor: 'pointer',
  },
  body: {
    cursor: 'pointer',
    position: 'relative',
    borderBottom: `1px solid ${theme.palette.graphColors.themeDarkGray}`,
  },
}))(TableCell);

const StackedDemandGraph = ({isMobile, data}) => {
  const theme = useTheme();
  const chartRef = useRef();
  const [demandData, setDemandData] = useState(null);
  const [disabledSources, setDisabledSources] = useState([]);
  const [enabledMetric, setEnabledMetric] = useState('Paid Imp');
  const [hoveredSource, setHoveredSource] = useState(null);

  // Used to hide separate graphs when enabled metric is cumulative
  const [hideSeparateGraphs, setHideSeparateGraphs] = useState(false);
  const classes = useStyles();

  const metricsConfig = createMetricsConfig(theme, hideSeparateGraphs);

  useEffect(() => {
    // Transform the data
    let graphData = [];
    // Get all available demand sources which are in this data
    let availableSources = getAvailableSources(data, sourcesConfig);

    data.forEach(day => {
      graphData.push({
        ...getStructuredFixedGraphDataForTheDay(day, metricsConfig, disabledSources),
        ...getStructuredDynamicGraphDataForTheDay(
          day,
          availableSources,
          metricsConfig,
          disabledSources
        ),
      });
    });

    // Calculate totals from graphData and availableSources
    let totals = getTotals(graphData, availableSources, metricsConfig);
    let totalsOfEnabledSources = getTotals(
      graphData,
      availableSources,
      metricsConfig,
      disabledSources
    );

    setDemandData({
      totals,
      totalsOfEnabledSources,
      graphData,
      availableSources,
    });
  }, [data, disabledSources]);

  // Amcharts 3 balloon function accepts HTML string as a return value
  const balloonFunction = (item, graph) => {
    let html =
      '<table style="border-radius: 4px; padding: 8px;  background: rgba(0,0,0,.8); border: none!important; color: #c7c7c7; text-align: left; font-size: 13px;  line-height: 15px; -webkit-font-smoothing: auto; font-family: Inter; width: 100%; margin-top: 7px;">';
    html +=
      '<tr style="font-weight: bold; font-size: 11px;"><td>' +
      moment(item.dataContext['date']).format('YYYY MMMM D') +
      '</td></tr>';

    let enabledMetricConfigKey = Object.keys(metricsConfig.perSource).find(
      key => metricsConfig.perSource[key].dataField === enabledMetric
    );

    let enabledMetricConfig = metricsConfig.perSource[enabledMetricConfigKey];

    html +=
      '<tr style="font-size: 16px;  box-shadow: 0 1px 0 0 rgba(255,255,255,.5);"><td style="font-weight: bold; padding-top: 5px; padding-bottom: 10px;">' +
      (enabledMetricConfig.cumulative
        ? `${enabledMetricConfig.displayName}`
        : `Avarage ${enabledMetricConfig.displayName}`) +
      '</td><td style="font-weight: bold; paddding-top: 5px; ">' +
      millify(item.dataContext[`enabledSources_${enabledMetricConfig.dataField}`]) +
      (enabledMetricConfig.symbol ?? '') +
      '<td/></tr>';

    demandData.availableSources.forEach(source => {
      Object.keys(metricsConfig.perSource).forEach(metric => {
        let config = metricsConfig.perSource[metric];
        let value = item.dataContext[`${source} ${config.dataField}`];
        let totalValue = item.dataContext[config.dataField];

        if (!enabledMetric.includes(config.dataField) || disabledSources.includes(source)) {
          return;
        }

        html +=
          `<tr><td style="min-width: 200px; padding-top: 5px; padding-right: 15px; color: ${
            theme.palette.graphColors[sourcesConfig[source].colorKey]
          }">
          <img
                    src=${sourcesConfig[source].logo}
                    alt=${source}
                    style="height: 16px;width: 16px; position: relative; top: 2px"
                  />
          ${source}
        ${config.displayName}</td></td>` +
          `<td style="padding-top: 5px; text-align: right; font-weight: bold">${millify(
            value
          )}${config.symbol ?? ''} 
          </td>

          <td style="padding-top: 5px; text-align: right;">
          ${metric !== 'eCPM' ? `(${getPercents(value, totalValue)}%)` : ''}
          </td>
          </tr>`;
      });
    });

    html += '</table>';

    return html;
  };

  const toggleSource = source => {
    if (!disabledSources.includes(source)) {
      setDisabledSources(disabledSources => [...disabledSources, source]);
    } else {
      setDisabledSources(disabledSources =>
        disabledSources.filter(disabledSource => disabledSource !== source)
      );
    }
  };

  const toggleMetric = metric => {
    setEnabledMetric(metric);
  };

  const rowsPerSource = () => {
    return demandData.availableSources.map(source => {
      let isSourceActive = !disabledSources.includes(source);
      return (
        <TableRow alignItems="center" spacing={2} container className={classes.sourceRow}>
          <StyledTableCell align="center">
            <Checkbox
              checked={isSourceActive}
              color={'primary'}
              onChange={() => toggleSource(source)}
              style={{float: 'left'}}
            />
            <Tooltip
              title={!disabledSources.includes(source) ? `Hide ${source}` : `Show ${source}`}
            >
              <Button
                variant={'text'}
                disabled={!isSourceActive}
                onClick={() => toggleSource(source)}
                style={{
                  textTransform: 'capitalize',
                  fontWeight: 'bold',
                }}
                endIcon={
                  <img
                    src={sourcesConfig[source].logo}
                    alt={source}
                    className={classes.domainImage}
                    style={isSourceActive ? {} : {opacity: 0.3}}
                  />
                }
              >
                {source}
              </Button>
            </Tooltip>
          </StyledTableCell>

          {Object.keys(metricsConfig.perSource)?.map(metric => {
            let config = metricsConfig.perSource[metric];
            let totalBySourceAndMetric =
              demandData.totals?.perSource[`${source} ${config.dataField}`] ?? 0;

            let isMetricActive = enabledMetric.includes(config.dataField);
            let isSourceActive = !disabledSources.includes(source);

            let tooltipText = !isMetricActive
              ? `Enable ${metric}`
              : `${isSourceActive ? 'Hide' : 'Show'} ${source}`;

            return (
              <Tooltip arrow title={tooltipText}>
                <StyledTableCell
                  onPointerEnter={() => setHoveredSource(isMetricActive ? source : false)}
                  onPointerLeave={() => setHoveredSource(false)}
                  onClick={() =>
                    !isMetricActive ? toggleMetric(config.dataField) : toggleSource(source)
                  }
                  align="center"
                  style={{
                    opacity: !isMetricActive || disabledSources.includes(source) ? 0.3 : 1,
                    background:
                      isSourceActive &&
                      enabledMetric.includes(metricsConfig.perSource[metric].dataField)
                        ? theme.palette.graphColors.sorted
                        : 'inherit',
                  }}
                >
                  <Typography
                    style={{
                      display: 'inline-block',
                      minWidth: 160,
                      fontWeight: 'bold',
                    }}
                  >
                    {millify(totalBySourceAndMetric)}
                    {config.symbol}{' '}
                    {config.comparisonWithTotalEnabled && (
                      <Typography variant={'caption'} display="inline" color={'textSecondary'}>
                        ({getPercents(totalBySourceAndMetric, demandData.totals[config.dataField])}
                        %)
                      </Typography>
                    )}
                  </Typography>

                  {enabledMetric.includes(metricsConfig.perSource[metric].dataField) &&
                    !disabledSources.includes(source) &&
                    !metricsConfig.perSource[metric].hideIndicator && (
                      <>
                        {config.indicatorType === 'column' && (
                          <div
                            style={{
                              ...config.indicatorStyle,
                              background: `linear-gradient(0deg, ${
                                theme.palette.graphColors[sourcesConfig[source].colorKey]
                              } 0%, ${
                                theme.palette.graphColors[sourcesConfig[source].colorKey]
                              } ${getPercents(
                                totalBySourceAndMetric,
                                demandData.totals[config.dataField]
                              )}%, rgba(0,0,0,0) ${parseInt(
                                getPercents(
                                  totalBySourceAndMetric,
                                  demandData.totals[config.dataField]
                                )
                              ) + 0}%)`,
                            }}
                          />
                        )}
                        {config.indicatorType === 'line' && (
                          <div
                            style={{
                              ...config.indicatorStyle,
                              background: theme.palette.graphColors[sourcesConfig[source].colorKey],
                            }}
                          />
                        )}
                        {config.indicatorType === 'dashed-line' && (
                          <div
                            style={{
                              ...config.indicatorStyle,
                              borderBottom: `4px dashed ${
                                theme.palette.graphColors[sourcesConfig[source].colorKey]
                              }`,
                            }}
                          />
                        )}
                      </>
                    )}
                </StyledTableCell>
              </Tooltip>
            );
          })}
        </TableRow>
      );
    });
  };

  const totalsHeader = () => {
    return Object.keys(metricsConfig.perSource).map(metric => {
      let config = metricsConfig.perSource[metric];
      let totalBySourceAndMetric = demandData.totalsOfEnabledSources[config.dataField];

      if (isNaN(totalBySourceAndMetric)) {
        totalBySourceAndMetric = 0;
      }

      return (
        <Grid
          item
          xs={12 / Object.keys(metricsConfig.perSource).length}
          style={{
            position: 'relative',
            opacity: !enabledMetric.includes(metricsConfig.perSource[metric].dataField) ? 0.5 : 1,
          }}
        >
          <Typography
            variant={'caption'}
            style={{
              display: 'inline-block',
              minWidth: 180,
            }}
          >
            {metricsConfig.perSource[metric].displayName}
          </Typography>
          <Typography variant={'h5'}>
            {millify(totalBySourceAndMetric)}
            {config.symbol}
          </Typography>
        </Grid>
      );
    });
  };

  const tableTotals = () => {
    return Object.keys(metricsConfig.perSource).map(metric => {
      let config = metricsConfig.perSource[metric];
      let totalBySourceAndMetric = demandData.totalsOfEnabledSources[config.dataField];
      let totalsBySource = demandData.totals[config.dataField];
      let isMetricActive = enabledMetric.includes(config.dataField);

      if (isNaN(totalBySourceAndMetric)) {
        totalBySourceAndMetric = 0;
      }

      return (
        <StyledTableCell
          style={{
            background: isMetricActive ? theme.palette.graphColors.themeDarkGray : 'inherit',
          }}
          align="center"
          onClick={() => toggleMetric(config.dataField)}
        >
          <Typography variant={'h6'}>
            {millify(totalBySourceAndMetric)}
            {config.symbol}
          </Typography>

          <Typography variant={'caption'} display="inline" color={'textSecondary'}>
            {getPercents(totalBySourceAndMetric, totalsBySource)}% of{' '}
            {config.comparisonWithTotalEnabled ? 'total' : 'avarage'}
          </Typography>
        </StyledTableCell>
      );
    });
  };

  const getValueAxis = () => {
    let valueAxis = [
      {
        id: 'requests',
        titleBold: false,
        minVerticalGap: 50,
        gridAlpha: 0,
        titleColor: theme.palette.graphColors.themeGray,
        color: theme.palette.graphColors.themeGray,
        axisColor: theme.palette.graphColors.themeGray,
        minimum: 0,
        gridColor: '#7979793b',
        position: 'right',
        title: 'Total requests and impressions by demand source',
        stackType: 'regular',
      },

      ...Object.keys(metricsConfig.perSource).map(metric => ({
        ...metricsConfig.perSource[metric].graphValueAxisConfig,
        titleBold: false,
        minVerticalGap: 50,
        gridAlpha: 1,
        gridColor: '#7979793b',
        titleColor: theme.palette.graphColors.themeGray,
        color: theme.palette.graphColors.themeGray,
        axisColor: theme.palette.graphColors.themeGray,
        minimum: 0,
        maximum: getMaxValueForGraph(
          demandData.graphData,
          metricsConfig.perSource[metric],
          sourcesConfig
        ),
      })),

      {
        id: 'revMax',
        titleBold: false,
        minVerticalGap: 50,
        gridAlpha: 0,
        titleColor: theme.palette.graphColors.themeGray,
        color: theme.palette.graphColors.themeGray,
        axisColor: theme.palette.graphColors.themeGray,
        minimum: 0,
        gridColor: '#7979793b',
        position: 'right',
        title: 'Total requests and impressions by demand source',
        stackType: 'regular',
      },
    ];

    return valueAxis;
  };

  const getGraphs = () => {
    // Requests are fixed graph
    let graphs = [
      // Add dynamic graph columns
      ...demandData.availableSources.map(source => ({
        clustered: false,
        id: `${source} Paid Imp`,
        type: 'column',
        newStack: false,
        valueAxis: 'requests',
        bullet: 'none',
        useLineColorForBulletBorder: true,
        lineColor: theme.palette.background.default,
        fillColors: theme.palette.graphColors[sourcesConfig[source].colorKey],
        lineAlpha: 0.7,
        lineAlpha: 0.8,
        fillAlphas: !hoveredSource || hoveredSource === source ? 0.9 : 0.1,
        lineThickness: 2,
        columnWidth: 0.8,
        legendValueText: source,
        legendPeriodValueText: source,
        valueField: `${source} Paid Imp`,
        hidden: disabledSources.includes(source) || !enabledMetric.includes('Paid Imp'),
        balloon: balloonConfig,
        balloonFunction: balloonFunction,
      })),
      // Add a fixed graph for requests
      {
        clustered: false,
        id: 'g1',
        type: 'column',
        valueAxis: 'requests',
        bullet: 'none',
        useLineColorForBulletBorder: true,
        lineColor: theme.palette.graphColors.themeDarkGray,
        lineAlpha: 1,
        lineThickness: 1,
        columnWidth: 0.8,
        fillAlphas: 0,
        legendValueText: 'requests',
        legendPeriodValueText: 'requests',
        valueField: 'requests',
        newStack: true,
        balloon: balloonConfig,
        hidden: !enabledMetric.includes('Paid Imp'),
        balloonFunction: balloonFunction,
      },

      // Add dynamic eCPM lines
      ...demandData.availableSources.map(source => ({
        type: 'smoothedLine',
        dashLength: 4,
        valueAxis: 'eCPM',
        bullet: 'none',
        useLineColorForBulletBorder: true,
        lineColor: theme.palette.graphColors[sourcesConfig[source].colorKey],
        lineThickness: 4,
        lineAlpha: !hoveredSource || hoveredSource === source ? 1 : 0.2,
        legendValueText: 'eCPM',
        legendPeriodValueText: 'eCPM',
        valueField: `${source} eCPM`,
        hidden:
          disabledSources.includes(source) || !enabledMetric.includes('eCPM') || hideSeparateGraphs,
        balloon: balloonConfig,
        balloonFunction: balloonFunction,
      })),

      // Add a fixed graph for average eCPM
      {
        type: 'smoothedLine',
        //dashLength: 4,
        valueAxis: 'eCPM',
        bullet: 'none',
        useLineColorForBulletBorder: true,
        lineColor: theme.palette.text.primary,
        lineAlpha: 0.8,
        lineThickness: 7,
        legendValueText: 'eCPM',
        legendPeriodValueText: 'eCPM',
        valueField: `enabledSources_eCPM`,
        hidden: !enabledMetric.includes('eCPM'),
        balloon: balloonConfig,
        balloonFunction: balloonFunction,
      },

      // Add dynamic revenue graph
      ...demandData.availableSources.map((source, i) => ({
        id: `${source} Rev`,
        type: 'smoothedLine',
        clustered: false,
        valueAxis: 'revenue',
        bullet: 'none',
        newStack: false,
        useLineColorForBulletBorder: true,
        fillColors: theme.palette.graphColors[sourcesConfig[source].colorKey],
        lineColor: theme.palette.graphColors[sourcesConfig[source].colorKey],
        lineAlpha: !hoveredSource || hoveredSource === source ? 0.8 : 0.1,
        lineThickness: 4,
        legendValueText: 'Revenue',
        legendPeriodValueText: 'Revenue',
        valueField: `${source} Rev`,
        hidden: disabledSources.includes(source) || !enabledMetric.includes('Rev'),
        fillAlphas: !hoveredSource || hoveredSource === source ? 0.8 : 0.1,
        balloon: balloonConfig,
        balloonFunction: balloonFunction,
      })),

      // Add fixed max revenue graph. Works only if it has collum type, newStack limitation.
      {
        id: `RevMax`,
        type: 'smoothedLine',
        clustered: false,
        valueAxis: 'revenueMax',
        columnWidth: 0.8,
        bullet: 'none',
        useLineColorForBulletBorder: true,
        lineColor: theme.palette.graphColors.themeDarkGray,
        lineThickness: 1,
        legendValueText: 'Revenue',
        legendPeriodValueText: 'Revenue',
        valueField: `Rev`,
        hidden: !enabledMetric.includes('Rev'),
        fillAlphas: 0,
        balloon: balloonConfig,
        balloonFunction: balloonFunction,
      },
    ];
    return graphs;
  };

  return (
    <>
      {/* {demandData && <Grid container>{totalsHeader()}</Grid>} */}

      {demandData && (
        <>
          <AmCharts.React
            ref={chartRef}
            style={{
              height: isMobile ? 300 : 420,
              width: '100%',
              transition: 'none!important',
            }}
            options={{
              columnSpacing: 0,
              zoomOutOnDataUpdate: true,
              type: 'serial',
              theme: 'light',
              marginTop: 15,
              marginRight: 15,
              responsive: {
                enabled: true,
              },

              dataProvider: demandData.graphData,
              categoryField: 'date',
              categoryAxis: {
                autoGridCount: false,
                labelFrequency: 1,
                tickLength: 1,
                gridCount: 12,
                labelRotation: 45,
                parseDates: true,
                gridAlpha: 0,
                color: theme.palette.graphColors.themeGray,
                axisColor: theme.palette.graphColors.themeLightGrayOpaque,
              },
              valueAxes: getValueAxis(),
              graphs: getGraphs(),
              chartCursor: {
                oneBalloonOnly: true,
                cursorColor: theme.palette.graphColors.themeGray,
                color: 'rgba(0,0,0,0)',
                categoryBalloonColor: 'rgba(0,0,0,0)',
                categoryBalloonDateFormat: 'YYYY-MM-DD',
                categoryBalloonAlpha: 1,
                cursorAlpha: 1,
                valueLineEnabled: true,
                valueLineBalloonEnabled: true,
                valueLineAlpha: 1,
                zoomable: false,
              },
              chartScrollbar: {
                enabled: false,
                backgroundAlpha: 0,
                dragIcon: 'dragIconRectSmall',
                graph: 'g1',
                graphFillAlpha: 0,
                graphLineAlpha: 0,
                scrollbarHeight: 30,
                color: theme.palette.primary.main,
                graphLineColor: theme.palette.graphColors.themeAlmostWhite,
                selectedGraphFillColor: theme.palette.graphColors.themeAlmostWhite,
                selectedGraphFillAlpha: 0,
                graphType: 'step',
                selectedBackgroundAlpha: 0.1,
                selectedBackgroundColor: theme.palette.graphColors.themeAlmostWhite,
              },
              dataDateFormat: 'YYYY-MM-DD',
              creditsPosition: 'bottom-right',
              zoomOutButton: {
                backgroundColor: '#e0e0e0',
                backgroundAlpha: 0,
              },
              zoomOutText: '',
              listeners: [],
            }}
          />
        </>
      )}

      {demandData && (
        <>
          <TableContainer style={{borderRadius: 4, marginTop: 30}}>
            <Table className={classes.table} component={Paper}>
              <TableHead>
                <TableRow>
                  <StyledTableCell
                    align="center"
                    style={{background: theme.palette.background.paper}}
                  >
                    Demand source
                  </StyledTableCell>
                  {Object.keys(metricsConfig.perSource).map(metric => (
                    <StyledTableCell
                      align="center"
                      style={{
                        background: enabledMetric.includes(
                          metricsConfig.perSource[metric].dataField
                        )
                          ? theme.palette.graphColors.themeDarkGray
                          : theme.palette.background.paper,
                      }}
                      onClick={() => toggleMetric(metricsConfig.perSource[metric].dataField)}
                    >
                      <Typography variant={'caption'}>
                        <>
                          <Tooltip
                            title={
                              enabledMetric.includes(metricsConfig.perSource[metric].dataField)
                                ? `Hide ${metric}`
                                : `Show ${metric}`
                            }
                          >
                            <Button
                              onClick={() =>
                                toggleMetric(metricsConfig.perSource[metric].dataField)
                              }
                              startIcon={
                                !enabledMetric.includes(
                                  metricsConfig.perSource[metric].dataField
                                ) ? (
                                  <VisibilityOffIcon />
                                ) : (
                                  <VisibilityIcon />
                                )
                              }
                              style={{textTransform: 'capitalize', fontWeight: 'bold'}}
                            >
                              {metricsConfig.perSource[metric].displayName}
                            </Button>
                          </Tooltip>
                        </>
                      </Typography>
                      <Typography>
                        {!metricsConfig.perSource[metric].cumulative && (
                          <FormControlLabel
                            disabled={
                              !enabledMetric.includes(metricsConfig.perSource[metric].dataField)
                            }
                            style={{marginLeft: 10}}
                            labelPlacement={'end'}
                            control={
                              <Switch
                                color={'primary'}
                                checked={!hideSeparateGraphs}
                                onChange={e => setHideSeparateGraphs(!e.target.checked)}
                              />
                            }
                            label={
                              <Typography variant={'caption'}>Draw separate sources</Typography>
                            }
                          />
                        )}
                      </Typography>
                    </StyledTableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <StyledTableCell align="center">
                    <Typography
                      variant={'body2'}
                      color={'textSecondary'}
                      style={{fontWeight: 'bold'}}
                    >
                      Total
                    </Typography>
                  </StyledTableCell>
                  {tableTotals()}
                </TableRow>
                {rowsPerSource()}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}
    </>
  );
};

export default React.memo(StackedDemandGraph);
